import api, { PublicAxios, OpenAxios } from './api';

class UserService {
  getSliderImages() {
    return PublicAxios.get('/promotions/');
  }

  getTablist() {
    return PublicAxios.get('/products/categories/');
  }

  getProducts(category) {
    return PublicAxios.get(`/products/?category=${category}`);
  }

  getNewsfeeds() {
    return api.get('/newsfeeds/');
  }

  getStartedGames(id) {
    return api.get(`/user-game-account/${id}`)
  }

  getStartedProducts(userId) {
    return api.get(`/user-game-account/${userId}`)
  }

  getBalance() {
    return api.get('/wallet/');
  }

  getNotificationCount() {
    return api.get('/notifications/count');
  }

  getPromotions() {
    return PublicAxios.get('/promotions/');
  }

  getBanks() {
    return OpenAxios.get('/banks/');
  }

  getBankDetails(bankId) {
    return OpenAxios.get(`/banks/${bankId}`)
  }

  getMerchantBanks() {
    return PublicAxios.get('/merchant-bank-accounts/');
  }

  getMerchantEWallets() {
    return PublicAxios.get('/merchant-ewallet-accounts/');
  }

  getBankDetails(bankId) {
    return PublicAxios.get(`/banks/${bankId}`)
  }

  getCustomBanks() {
    return api.get('/customer-bank-accounts/');
  }

  getCouponTypelist() {
    return api.get('/coupon-types/');
  }

  createDeposit(values) {
    return api.post('/deposits/', values);
  }

  withdraw(values) {
    return api.post('/withdrawals/', values);
  }

  startProduct(id, selectedCredit) {
    return api.post(`/product/${id}/start/?credit_type=${selectedCredit}`);
  }

  stopProduct(id) {
    return api.post(`/product/${id}/stop/`);
  }

  startEasytechProduct(id, selectedCredit) {
    return api.post(`/product/${id}/easytech/start/?credit_type=${selectedCredit}`);
  }

  stopEasytechProduct(id) {
    return api.post(`/product/${id}/easytech/stop/`);
  }

  checkProduct(id) {
    return api.get(`/product/${id}/game-account/`)
  }

  getOrders(id) {
    return api.get(`/actors/transactions/`)
  }

  getTransfers(id) {
    return api.get(`/transfers/`)
  }

  getBonues(id) {
    return api.get(`/bonuses/`)
  }

  deleteActor(userId, id) {
    return api.delete(`/actors/transactions/${id}`)
  }

  getAppAccounts() {
    return api.get(`/game-accounts/?ltype=app`)
  }

  getBetHistory(userId) {
    return api.get(`/user-bet-history`, userId)
  }

  addCustomerBank(values) {
    return api.post('/customer-bank-accounts/', values)
  }
}

export default new UserService();
