import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUp2 from "./pages/SignUp2";
import SignUp3 from "./pages/SignUp3";
import Notifications from "./pages/Notifications";
// import Transactions from "./pages/TransactionHistory";
// import Bets from "./pages/BetHistory";
import Account from "./pages/Account";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import Promotions from "./pages/Promotions";
import Settings from "./components/Settings";
import DepositNew from "./components/DepositNew";
import HistoryTabs from "./components/HistoryTabs";
import { ToastContainer } from "react-toastify";
import Forget from "./pages/Forget";
import VerifyTac from "./pages/VerifyTac";
import EWallets from "./pages/EWallets";
import EWallet from "./pages/EWallet";
import EWalletTransfer from "./components/EWalletTransfer";
import userService from "./service/user.service";
import authService from "./service/auth.service";
import MainLayout from "./MainLayout";
import AuthLayout from "./AuthLayout";
export const UserContext = React.createContext();
export const AppContext = React.createContext();

function App() {
  const location = useLocation();
  const [mode, setMode] = useState(localStorage.getItem("PayApp-Theme"));
  const [active, setActive] = useState(1);

  const [appData, setAppData] = useState({
    userId: 0,
    signupStep: 0,
    refreshToken: false,
    accessToken: false,
    promotions: [],
    categories: [],
    categoriesDetails: [],
    categoryLoading: false,
    wallet: {
      balance: "0.00",
      currency: "",
      id: "",
      user: "",
      username: "Username",
    },
  });
  const [isAuth, setAuth] = useState(true);


  useEffect(() => {
    window.addEventListener("storage", () => {
      setMode(localStorage.getItem("PayApp-Theme"));
    });
    window.addEventListener("resize", card_extender);
  }, []);

  function card_extender() {
    const cards = document.getElementsByClassName("card");
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].getAttribute("data-card-height") === "cover") {
        if (window.matchMedia("(display-mode: fullscreen)").matches) {
          var windowHeight = window.outerHeight;
        }
        if (!window.matchMedia("(display-mode: fullscreen)").matches) {
          windowHeight = window.innerHeight;
        }
        var coverHeight = windowHeight + "px";
      }
      if (cards[i].getAttribute("data-card-height") === "cover-card") {
        windowHeight = window.innerHeight;
        coverHeight = windowHeight - 200 + "px";
        cards[i].style.height = coverHeight;
      }
      if (cards[i].getAttribute("data-card-height") === "cover-full") {
        if (window.matchMedia("(display-mode: fullscreen)").matches) {
          windowHeight = window.outerHeight;
        }
        if (!window.matchMedia("(display-mode: fullscreen)").matches) {
          windowHeight = window.innerHeight;
        }
        coverHeight = windowHeight + "px";
        cards[i].style.height = coverHeight;
      }
      if (cards[i].hasAttribute("data-card-height")) {
        var getHeight = cards[i].getAttribute("data-card-height");
        cards[i].style.height = getHeight + "px";
        if (getHeight === "cover") {
          cards[i].style.height = coverHeight;
        }
      }
    }
  }

  useEffect(() => {
    card_extender();
  }, [location]);

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [localStorage.getItem("user")]);

  return (
    <UserContext.Provider value={{ mode, active, setActive }}>
      <AppContext.Provider value={[appData, setAppData]}>
        <ToastContainer />
        <Routes>
          <Route
            path="/*"
            element={
              isAuth ? (
                <MainLayout setAuth={setAuth} />
              ) : (
                <AuthLayout setAuth={setAuth} />
              )
            }
          />
        </Routes>
      </AppContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
