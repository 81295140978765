import React, { useState, useEffect, useContext } from "react";
import { AppContext, UserContext } from '../App'
import Select from "react-select";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer'
import Header from '../components/Header'
import MenuNotification from '../components/MenuNotification'
import MenuSidebar from "../components/MenuSidebar";
import authService from "../service/auth.service";
import userService from "../service/user.service";

const initialValues = {
    amount: "",
    customer_bank_account: "",
    bank_number: "",
};

function Withdraw({ walletInfo }) {
    const [values, setValues] = useState(initialValues);
    const [appData] = useContext(AppContext);
    const [cuBanks, setCuBanks] = useState([]);
    const [bankNumber, setBankNumber] = useState();
    const [totalBanks, setTotalBanks] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [isTxnModal, setIsTxnModal] = useState(false);
    const [txnId, setTxnId] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [creditTypes, setCreditTypes] = useState([
        {
            value: "CA",
            label: "Cash",
        },
        {
            value: "CH",
            label: "Chips",
        },
    ]);
    const [selectedCredit, setSelectedCredit] = useState("");

    const navigate = useNavigate();

    const [validations, setValidations] = useState({
        amount: "",
        customer_bank_account: "",
        non_field_errors: "",
    });

    const {
        amount: amountVal,
        customer_bank_account: bank_Val,
        non_field_errors: nonVal,
        credit: creditValue
    } = validations;

    const getCustomBanklist = async () => {
        let totalbanks;
        let custombanks;
        try {
            totalbanks = await userService.getBanks();
            custombanks = await userService.getCustomBanks();
        } catch (err) {
            console.log("err ==> ", err);
        }
        setTotalBanks(totalbanks.data);
        let custombanklist = [];
        custombanks && custombanks.data.map((game) => {
            if (game) {
                custombanklist.push({
                    value: game.id,
                    label: game.name,
                    icon: game.icon,
                    number: game.number,
                });
            }
        });
        setCuBanks(custombanklist);
    };

    const handleWithdraw = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (selectedCredit === "") {
            setValidations({
                ...validations,
                credit: "Please select credit type"
            })
        }

        try {
            console.log(values);
            const response = await userService.withdraw(values);
            // Modal.success({
            //     content: 'Withdraw is successful',
            // });
            setTxnId(response.data.id);
            setIsTxnModal(true);
            setTimeout(() => {
                navigate("/account");
            }, 5000);
            setValues(initialValues);
        } catch (err) {
            console.log("err => ", err, values);
            let non_msg;
            let amount_msg;
            let bank_msg;


            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.amount
            ) {
                amount_msg = err.response.data.amount;
            }
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.customer_bank_account
            ) {
                bank_msg = err.response.data.customer_bank_account;
            }
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.non_field_errors
            ) {
                non_msg = err.response.data.non_field_errors;
            }
            setValidations({
                ...validations,
                amount: amount_msg,
                customer_bank_account: bank_msg,
                non_field_errors: non_msg,
                credit: selectedCredit === "" ? "Please select credit type" : ""
            });
        }
        setLoading(false);
    };

    const handleCreditChange = (e) => {
        setSelectedCredit(e.value);
        setValues({
            ...values,
            credit_type: e.value
        })
    };

    const handleChange = (e) => {
        const bankNumber = cuBanks.find((item) => item.value === e.value).number;
        setBankNumber(bankNumber);
        setValues((values) => {
            return { ...values, customer_bank_account: e.value };
        });
    };

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === "") {
            setValues((values) => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    };

    const maxAmount = () => {
        if (walletInfo && selectedCredit) {
            let bal;
            if (selectedCredit === 'CA') {
                bal = walletInfo.balance;
            } else {
                bal = walletInfo.chips_balance;
            }
            setValues((values) => {
                return { ...values, amount: bal };
            });
        }
    };

    const getUserInfo = async () => {
        const user = await authService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    };

    useEffect(() => {
        getCustomBanklist();
        getUserInfo();
    }, []);

    return (
        <div className='t1'>
            <div className='page'>
                <div className="page-content footer-clear">
                    <Header />
                    <div className="card card-fixed">
                        <div className="card-center mx-3 rounded-m" style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}>
                            <div className="w-full text-white pink-bg p-10 flex items-center justify-between rounded-lg mb-3">
                                <div className="w-half flex justify-center items-center cash-border flex-col py-2.5">
                                    <div className="text-sm">Cash ( MYR )</div>
                                    <div className="text-2xl font-bold mt-1">
                                        {currentUser && walletInfo ? walletInfo.balance : "0.00"}
                                    </div>
                                </div>
                                <div className="w-half flex justify-center items-center flex-col py-2.5">
                                    <div className="text-sm">Chips ( MYR )</div>
                                    <div className="text-2xl font-bold mt-1">
                                        {currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}
                                    </div>
                                </div>
                            </div>
                            <div className={`mb-3`}>
                                <Select
                                    options={cuBanks}
                                    formatOptionLabel={bank => (
                                        <div className="flex items-center gap-1" key={bank.id}>
                                            <img src={bank.icon} alt="" className="w-10 h-10" />
                                            <span className='text-black' style={{ marginLeft: "auto", marginRight: "10px", flex: "0.8" }}>{bank.label}</span>
                                            <span className='text-black' style={{ marginLeft: "auto", marginRight: "10px", flex: "0.1" }}>{bank.number}</span>
                                        </div>
                                    )}
                                    onChange={handleChange}
                                    aria-label="Floating label select example"
                                    placeholder="--Choose Your Account--"
                                />
                                <div
                                    className={`${bank_Val === "" ? "valid-feedback" : "invalid-feedback"
                                        } `}
                                >
                                    {bank_Val}
                                </div>
                            </div>
                            <div className={`mb-3`}>
                                <Select
                                    onChange={handleCreditChange}
                                    defaultValue={selectedCredit}
                                    placeholder="--Choose Credit Type--"
                                    options={creditTypes}
                                />
                                <div
                                    className={`${creditValue === "" ? "valid-feedback" : "invalid-feedback"
                                        } `}
                                >
                                    {creditValue}
                                </div>
                            </div>
                            <div className="position-relative form-label form-icon bg-transparent">
                                <input
                                    type="number"
                                    className="form-control rounded-xxs"
                                    id="c1a"
                                    placeholder="Amount"
                                    onChange={handleNumChange}
                                    value={values.amount}
                                    name="amount"
                                    style={{ height: "53px" }}
                                />
                                <div
                                    className="position-absolute w-fit top-0 max-withdraw-btn"
                                    onClick={maxAmount}
                                    style={{ paddingTop: "5px" }}
                                >
                                    MAX
                                </div>
                            </div>
                            <div
                                className={`${amountVal === "" ? "valid-feedback" : "invalid-feedback"
                                    } `}
                            >
                                {amountVal}
                            </div>
                            <div
                                className={`${nonVal === "" ? "valid-feedback" : "invalid-feedback"
                                    } `}
                            >
                                {nonVal}
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn btn-xl gradient-highlight shadow-bg shadow-bg-s mt-4"
                                    onClick={handleWithdraw}
                                >
                                    Withdraw
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <MenuSidebar />
                <MenuNotification />
            </div>
            <Modal
                title="Congratulations!"
                style={{ top: "50px" }}
                open={isTxnModal}
                onCancel={() => setIsTxnModal(false)}
                footer={[]}
            >
                <h3 className="text-lg font-bold">Withdraw is successful.</h3>
                <p className="font-bold mt-1">
                    Transaction Id: <span className="text-lime-700">{txnId}</span>
                </p>
                <div className="w-full d-flex justify-end gap-3 mt-3">
                    <Button onClick={() => setIsTxnModal(false)}>Cancel</Button>
                    <Button
                        className="bg-sky-600"
                        onClick={() => navigate("/")}
                    >
                        OK
                    </Button>
                </div>
            </Modal>
        </div >
    )
}

export default Withdraw