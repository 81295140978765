import "./pages.css";
import React, { useEffect, useState } from "react";
import userService from "../service/user.service";
import { Empty, Input, Tag } from "antd";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MenuSidebar from "../components/MenuSidebar";

const GameAccounts = ({walletInfo}) => {
  const [accounts, setAccounts] = useState([]);

  const fetchAppAccounts = async () => {
    try {
      const result = await userService.getAppAccounts();
      if (result.data && result.status === 200) {
        const data = result.data;
        setAccounts(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAppAccounts();
  }, []);

  return (
    <div className="t1">
      <Header />
      <div className="game-accounts-container">
        {accounts && accounts.length ?
        accounts.map((item) => (
          <div className="account-item">
            <img src={item.product.image_mobile} alt="game-thumbnail" />
            <div className="account-content">
              <div>
                <p>{item.login}</p>
                <Input.Password className="pass" value={item.password} />
              </div>
              <Tag
                style={{ marginTop: "8px", fontWeight: "400" }}
                color="#ebad02"
              >
                {item.product.category.toUpperCase()}
              </Tag>
            </div>
            <h3>
              {walletInfo ? walletInfo.balance : 0.00} <span>(MYR)</span>
            </h3>
          </div>
        )) :
        <Empty description="No items found!" />
      }
      </div>
      <Footer />
      <MenuSidebar />
    </div>
  );
};

export default GameAccounts;

