import React from 'react'
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer'
import Header from '../components/Header'
import MenuNotification from '../components/MenuNotification'
import MenuSidebar from '../components/MenuSidebar'
import EWalletTransfer from '../components/EWalletTransfer';

function EWallet({ ewalletsList, getMerchantEWalletslist }) {

    const id = useParams();
    const index = Object.values(id)[0];

    return (
        <div className='t1'>
            <div className='page'>
                <div className="page-content footer-clear">
                    <Header />
                    <div className="">
                        <div className="mt-4 mx-2 px-2 p-0 pt-3 rounded-m">
                            <EWalletTransfer ewalletid={index} ewalletsList={ewalletsList} getMerchantEWalletslist={getMerchantEWalletslist} />
                        </div>
                    </div>
                </div>
                <Footer />
                <MenuNotification />
                <MenuSidebar />
            </div>
        </div>
    )
}

export default EWallet