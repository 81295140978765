import api, { OpenAxios } from "./api";
import TokenService from "./token.service";

class AuthService {
    login(values) {
        return api
            .post("/token/", values)
            .then((response) => {
                if (response.data && response.data.access) {
                    TokenService.setUser(response.data);
                    return response.data;
                }

            });
    }

    logout() {
        TokenService.removeUser();
    }

    register(values) {
        return OpenAxios.post("/signup/", values);
    }

    getCurrentUser() {
        return TokenService.getUser();
    }

    getTac(userId) {
        return OpenAxios.post(`/signup/${userId}/get-tac/`);
    }

    getForgetTac(values) {
        return OpenAxios.post(`/reset-password/get-tac/`, values);
    }

    verifyTac({ userId, code }) {
        return OpenAxios.post(`/signup/${userId}/verify-tac/`, { "tac": code });
    }

    activate({ userId, values }) {
        return OpenAxios.post(`/signup/${userId}/activate/`, values);
    }

    getBanklist() {
        return OpenAxios.get(`/banks/`);
    }

    changePassword({ values, userId }) {
        return api.put(`/change-password/${userId}/`, values)
    }

    resetPassword(values) {
        return api.post(`/reset-password/reset/`, values);
    }
}

export default new AuthService();
