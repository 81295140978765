import React, { useContext, useEffect, useState } from 'react'
import Swal from "sweetalert2"
import { Modal } from "antd";
import { Icon } from "@iconify/react";
import BalanceWrapper from '../components/BalanceWrapper'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MenuNotification from '../components/MenuNotification'
import Tabs from '../components/Tabs'
import TopSlider from '../components/TopSlider'
// import { getCategoryDetail, getNotificationCount, getPromotions, getWallet, productCategories } from '../services'
import { AppContext } from '../App'
import MenuSidebar from '../components/MenuSidebar'
import userService from '../service/user.service'
import Loading from '../components/Loading'
import Gallery from '../components/Gallery'
import authService from '../service/auth.service';
import BalanceSection from '../components/BalanceSection';

function Home({ getWalletInfo, walletInfo }) {
    const [state, setState] = useContext(AppContext)
    const [tablist, setTabs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [gamelist, setGamelist] = useState([]);
    const [tabNum, setTabNum] = useState("news");
    const [active, setActive] = useState(0);
    const [startedGameList, setStartedGameList] = useState([]);
    const [playModal, setPlayModal] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null);
    const [selectedCredit, setSelectedCredit] = useState("");
    const [androidGameUrl, setAndroidGameUrl] = useState("");
    const [ios32GameUrl, setios32GameUrl] = useState("");
    const [ios64GameUrl, setios64GameUrl] = useState("");
    const [gameData, setGameData] = useState();
    const [isOpen, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [text, setText] = useState("");
    const [images, setImages] = useState([]);
    const [newsfeeds, setNewsfeeds] = useState([]);

    var userAgent = navigator.userAgent.toLowerCase();

    const getSliderImgs = async () => {
        setLoading(true);
        const games = await userService.getSliderImages();
        let imgArr = [];
        games.data.map((game) => {
            if (game.image) {
                imgArr.push({ imgSrc: game.image });
            }
        });
        setImages(imgArr);
        setLoading(false);
    };

    const getTabslist = async () => {
        setLoading(true);
        const tabslist = await userService.getTablist();
        setTabs(tabslist.data);
        setLoading(false);
    };

    const getNewsfeedsList = async () => {
        setLoading(true);
        try {
            const newslist = await userService.getNewsfeeds();
            const test = undefined || [];
            console.log("map function 2", test, newslist.data || [], newslist)
            if (newslist && newslist.data && Array.isArray(newslist.data)) {
                setNewsfeeds(newslist.data);
            } else {
                setNewsfeeds([]);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    const getProductlist = async () => {
        if (tabNum === "news") {
            setGamelist([]);
            return
        }

        setLoading(true);
        try {
            const productlist = await userService.getProducts(tabNum);
            setGamelist(productlist.data);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    const getGameStartedList = async () => {
        try {
            const userInfo = await userService.getBalance();
            const startedGameList = await userService.getStartedGames(
                userInfo.data.user.id
            );
            setStartedGameList(startedGameList.data);
        } catch (err) {
            console.log(err);
        }
    };

    const handleProduct = async (game) => {
        setPlayModal(true);
        setSelectedGame(game);
        if (game.credit_type === "CC") {
            if (walletInfo.balance > 0) {
                setSelectedCredit("CA")
            } else if (walletInfo.chips_balance > 0) {
                setSelectedCredit("CH")
            }
        } else if (game.credit_type === "CA") {
            setSelectedCredit("CA")
        } else if (game.credit_type === "CH") {
            setSelectedCredit("CH")
        }
    };

    const handleGameLaunch = async () => {
        const { ltype, urltype, id } = selectedGame;
        initiateProduct(ltype, urltype, id);
        setPlayModal(false);
    };

    const initiateProduct = async (ltype, urltype, id) => {
        if (startedGameList.map((item) => item.product_id).includes(id)) {
            await StopProduct(id, urltype);
        }

        setLoading(true);

        try {
            if (ltype === "h5") {
                const win = window.open("about:blank");
                let res = await userService.startProduct(id, selectedCredit);
                await getWalletInfo();
                win.location = res.data.url;
            }

            if (ltype === "app") {
                const res = await userService.startProduct(id, selectedCredit);
                setGameData(res.data);
                if (res.data.name === "Pussy888") {
                    setAndroidGameUrl(
                        `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
                    );
                    setios32GameUrl(
                        `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
                    );
                    setios64GameUrl(
                        `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
                    );
                } else if (res.data.name === "918kiss") {
                    setAndroidGameUrl(
                        `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
                    );
                    setios32GameUrl(
                        `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
                    );
                    setios64GameUrl(
                        `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
                    );
                } else if (res.data.name === "Mega888") {
                    setAndroidGameUrl(
                        `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
                    );
                    setios32GameUrl(
                        `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
                    );
                    setios64GameUrl(
                        `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
                    );
                } else if (res.data.name === "Evo888") {
                    setAndroidGameUrl(
                        `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
                    );
                    setios32GameUrl(
                        `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
                    );
                    setios64GameUrl(
                        `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
                    );
                }
                setOpen(true);
                await getWalletInfo();
            }
            await getGameStartedList();
        } catch (err) {
            console.log(err);
            if (err.response.data.detail) {
                Swal.fire({
                    icon: "error",
                    text: `${err.response.data.detail}`,
                });
                window.close();
            }
        }
        setLoading(false);
    };

    const StopProduct = async (id, urltype) => {
        setLoading(true);
        try {
            await userService.stopProduct(id);
            await getWalletInfo();
            await getGameStartedList();
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    const copy = async (copyText) => {
        if (copyText !== "") {
            await navigator.clipboard.writeText(copyText);
            setText(copyText);
        }
    };

    useEffect(() => {
        getSliderImgs();
        getTabslist();
        getNewsfeedsList();

    }, []);

    useEffect(() => {
        if (text === "") {
            setText("");
        }
    }, [text]);

    useEffect(() => {
        getProductlist();
        getGameStartedList();
    }, [tabNum]);

    useEffect(() => {
        const user = authService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    }, [localStorage.getItem("user")]);

    return (
        <div className='t1'>
            <div className='page'>
                <div className="page-content footer-clear">
                    <Header />
                    <TopSlider images={images} />
                    <BalanceWrapper newsfeeds={newsfeeds} startedGamelist={startedGameList} StopProduct={StopProduct} setStartedGamelist={setStartedGameList} />
                    <BalanceSection
                            getWalletInfo={getWalletInfo}
                            walletInfo={walletInfo}
                            // isRefresh={isRefresh}
                            startedGameList={startedGameList}
                            StopProduct={StopProduct}
                            setStartedGameList={setStartedGameList}
                        />
                    <div className="content t3 d-flex" id="tab-group-1" style={{ margin: "0px 8px" }}>
                        <Tabs tablist={tablist} setTabNum={setTabNum} active={active} setActive={setActive} />
                        <div className="clearfix me-1"></div>
                        <Gallery
                            tablist={tablist}
                            gamelist={gamelist}
                            active={active}
                            getProductlist={getProductlist}
                            tabNum={tabNum}
                            startedGameList={startedGameList}
                            handleProduct={handleProduct}
                            StopProduct={StopProduct}
                        />
                    </div>
                </div>
                <Footer />
                <MenuNotification />
                <MenuSidebar />
            </div>
            {
                isLoading && <Loading />
            }
            {isOpen && (
                <Modal centered open={true} onCancel={() => setOpen(false)} footer={[]}>
                    <div className="download-app-info">
                        <div className="balance-wrap">
                            Balance In Game: {gameData && gameData.credit ? gameData.credit : 0.0}
                        </div>
                        <div className="accountInfo-wrap">
                            <div className="d-flex justify-between my-10">
                                <div className="d-flex flex-col justify-center text-12 font-bold">
                                    USERNAME:
                                </div>
                                <div className="clipboard-wrap d-flex justify-between bg-silver">
                                    <div>{gameData.username}</div>
                                    <div onClick={() => copy(gameData.username)}>
                                        {text === gameData.username ? (
                                            <Icon
                                                icon="material-symbols:check"
                                                width={20}
                                                color="green"
                                            />
                                        ) : (
                                            <Icon icon="bxs:copy" width={20} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-between">
                                <div className="d-flex flex-col justify-center text-12 font-bold">
                                    PASSWORD:
                                </div>
                                <div className="clipboard-wrap d-flex justify-between bg-silver">
                                    <div>{gameData.password}</div>
                                    <div onClick={() => copy(gameData.password)}>
                                        {text === gameData.password ? (
                                            <Icon
                                                icon="material-symbols:check"
                                                width={20}
                                                color="green"
                                            />
                                        ) : (
                                            <Icon icon="bxs:copy" width={20} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-between w-full">
                                {gameData && gameData.url.android[0].includes("https://") ? (
                                    <a
                                        href={`${gameData.url.android}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="download-apk-btn d-flex">
                                            <div className="d-flex flex-col justify-center">
                                                Android Download
                                            </div>
                                            <div className="d-flex flex-col justify-center">
                                                <Icon icon="uil:android" width={20} />
                                            </div>
                                        </div>
                                    </a>
                                ) : (
                                    <a
                                        href={`https://${gameData.url.android}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="download-apk-btn d-flex">
                                            <div className="d-flex flex-col justify-center">
                                                Android Download
                                            </div>
                                            <div className="d-flex flex-col justify-center">
                                                <Icon icon="uil:android" width={20} />
                                            </div>
                                        </div>
                                    </a>
                                )}

                                {gameData.url.ios[0].includes("https://") ? (
                                    <a
                                        href={`${gameData.url.ios}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="download-apk-btn d-flex">
                                            <div className="d-flex flex-col justify-center">
                                                iOS Download
                                            </div>
                                            <div className="d-flex flex-col justify-center">
                                                <Icon icon="ic:baseline-apple" width={20} />
                                            </div>
                                        </div>
                                    </a>
                                ) : (
                                    <a
                                        href={`https://${gameData.url.ios}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="download-apk-btn d-flex">
                                            <div className="d-flex flex-col justify-center">
                                                iOS Download
                                            </div>
                                            <div className="d-flex flex-col justify-center">
                                                <Icon icon="ic:baseline-apple" width={20} />
                                            </div>
                                        </div>
                                    </a>
                                )}
                            </div>
                            {userAgent.indexOf("android") > -1 ? (
                                <div className="d-flex justify-between w-full">
                                    <a
                                        className="w-full"
                                        href={androidGameUrl ? androidGameUrl : "/"}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="play-btn">
                                            <div className="d-flex flex-col justify-center">
                                                Play Now
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ) : (
                                <>
                                    {userAgent.indexOf("iphone") > -1 &&
                                        window.innerWidth <= 320 ? (
                                        <div className="d-flex justify-between w-full">
                                            <a
                                                className="w-full"
                                                href={ios32GameUrl ? ios32GameUrl : "/"}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className="play-btn">
                                                    <div className="d-flex flex-col justify-center">
                                                        Play Now
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-between w-full">
                                            <a
                                                className="w-full"
                                                href={ios64GameUrl ? ios64GameUrl : "/"}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className="play-btn">
                                                    <div className="d-flex flex-col justify-center">
                                                        Play Now
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            {selectedGame && (
                <Modal
                    title={selectedGame.name}
                    footer={[]}
                    open={playModal}
                    onCancel={() => setPlayModal(false)}
                    style={{ textAlign: "center" }}
                >
                    <div>
                        <div className="d-flex flex-row justify-center">
                            <img
                                src={selectedGame.image_mobile}
                                alt={selectedGame.name}
                                style={{ width: "150px", borderRadius: "1rem" }}
                            />
                        </div>
                        <p className="text-md font-medium mt-3">
                            Note: The game supports{" "}
                            {selectedGame.credit_type === "CA" && "only CASH. "}
                            {selectedGame.credit_type === "CH" && "only CHIPS. "}
                            {selectedGame.credit_type === "CC" &&
                                "Cash and Chips both types. "}
                            Please select an option.
                        </p>
                        <div className="d-flex flex-row justify-center items-center my-3 gap-x-3">
                            {(selectedGame.credit_type === "CC" ||
                                selectedGame.credit_type === "CA") && (
                                    <div
                                        onClick={() => setSelectedCredit("CA")}
                                        className="d-flex items-center justify-center gap-2
            balance-account w-1/2 p-3"
                                        style={{
                                            border: "1px solid #ccc",
                                            borderRadius: "1rem",
                                            borderColor:
                                                selectedCredit === "CA" ? "rgb(92, 149, 224)" : "#ccc",
                                            background:
                                                selectedCredit === "CA"
                                                    ? "rgba(92, 149, 224, .2)"
                                                    : "transparent",
                                        }}
                                    >
                                        <span className="icon icon-lg cash-custom-icon"></span>
                                        <div
                                            className={`d-flex flex-col items-end text-stone-900`}
                                            style={{ fontWeight: "bold" }}
                                        >
                                            <h6>Cash</h6>
                                            <h6>{currentUser && walletInfo ? walletInfo.balance : "0.00"}</h6>
                                        </div>
                                    </div>
                                )}
                            {(selectedGame.credit_type === "CC" ||
                                selectedGame.credit_type === "CH") && (
                                    <div
                                        onClick={() => setSelectedCredit("CH")}
                                        className="d-flex items-center justify-center gap-2 balance-account w-1/2 p-3"
                                        style={{
                                            border: "1px solid #ccc",
                                            borderRadius: "1rem",
                                            borderColor:
                                                selectedCredit === "CH" ? "rgb(92, 149, 224)" : "#ccc",
                                            background:
                                                selectedCredit === "CH"
                                                    ? "rgba(92, 149, 224, .2)"
                                                    : "transparent",
                                        }}
                                    >
                                        <span className="icon icon-lg chips-custom-icon"></span>
                                        <div
                                            className={`d-flex flex-col items-end text-stone-900`}
                                            style={{ fontWeight: "bold" }}
                                        >
                                            <h6>Chips</h6>
                                            <h6>{currentUser ? walletInfo.chips_balance : "0.00"}</h6>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <button
                            onClick={handleGameLaunch}
                            className={`game-btn-active m-auto`}
                        >
                            Play Now
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Home