import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { toast } from 'react-toastify'
import Header from '../components/Header'

import authService from '../service/auth.service'

const initialValues = {
    cc: "60",
    mobile: "",
};

function Forget() {
    const [values, setValues] = useState(initialValues);
    const navigate = useNavigate();

    const handleSendTac = async () => {
        try {
            const result = await authService.getForgetTac(values);

            console.log("result ===> ", result);

            const mobile = result.data.recipient;
            toast.success(`TAC sent to your mobile +${mobile}`, { theme: "light" });
            navigate('/verify-tac', { state: values });
        } catch (err) {
            toast.error(`Failed to send Tac. Please try again later!`, { theme: 'light' });
            console.log(err);
        }
    };

    return (
        <div className="t3" >
            <div id="page">
                <div className="page-content pb-0">
                    <div data-card-height="cover-full" className="card mb-0 mx-0">
                        <div className="t1">
                            <Header />
                        </div>
                        <div className="card-center">
                            <div className="content">
                                <div>
                                    <h1 className="font-23 font-700" style={{ color: '#ffc107' }}>Forget Password?</h1>
                                </div>
                                <p className="input-title mb-3 font-14" style={{ color: "#223651" }}>Don't worry it happens. Please enter the phone number associated with your account!</p>
                                <PhoneInput
                                    inputStyle={{ width: "100%", borderRadius: '0px', height: "40px" }}
                                    containerStyle={{ marginBottom: "10px" }}
                                    buttonStyle={{ borderRadius: '0' }}
                                    country={'my'}
                                    onlyCountries={['my', 'sg']}
                                    localization={{ my: 'Malaysia', sg: 'Singapore' }}
                                    onChange={(phone, country) => {
                                        const reducedPhone = phone.replace(country.dialCode, '',);
                                        setValues({ ...values, cc: country.dialCode, mobile: reducedPhone })
                                    }}
                                />

                                <div
                                    data-back-button
                                    className="btn mx-auto w-100 btn-full btn_green register_btn mt-4"
                                    onClick={handleSendTac}
                                >
                                    NEXT
                                </div>

                            </div>
                        </div>
                        {/* <div className="t1">
                            <Footer />
                        </div> */}
                        <div className="card-overlay bg-black"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forget