import React, { useState, useEffect } from 'react'

function Gallery({ gamelist, startedGameList, StopProduct, handleProduct }) {

    return (
        <div className="w-75" >
            <div className="row me-0 ms-0 mb-0 position-relative">
                {startedGameList && gamelist.map((game, i) => (
                    <div className="ps-0 pe-0" key={i}>
                        <div className="card card-style" style={{ height: "150px", minWidth: "250px" }}>
                            <img
                                src={game.image_mobile ? (game.is_live ? game.image_mobile : '/images/game01.png') : '/images/game01.png'}
                                alt=''
                            />
                            <div className='position-absolute d-flex flex-col justify-center h-100 w-100'>
                                <div className='d-flex justify-center'>
                                    <div
                                        className={`${startedGameList.map(item => item.product_id).includes(game.id) ? 'game-btn-active' : 'game-btn-inactive'} m-0`}
                                        onClick={() => {
                                            if (game.id) {
                                                if (startedGameList.map(item => item.product_id).includes(game.id)) {
                                                    StopProduct(game.id, game.urltype);
                                                } else {
                                                    handleProduct(game);
                                                }
                                            }
                                        }}
                                    >
                                        {startedGameList.map(item => item.product_id).includes(game.id) ? 'End' : 'Play'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Gallery