import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../App'
import PaymentGateway from '../components/ManualTransfer'
import OnlineTransfer from '../components/OnlineTransfer'
import ReloadCard from '../components/ReloadCard'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MenuNotification from '../components/MenuNotification'
import MenuSidebar from '../components/MenuSidebar'
import ManualTransfer from '../components/ManualTransfer'
import userService from '../service/user.service';
import Loading from '../components/Loading';

function EWallets({ type }) {
    const [ewalletsList, setEWalletsList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getMerchantEWalletslist();
    }, [])

    const getMerchantEWalletslist = async () => {
        setLoading(true);
        const ewallets = await userService.getMerchantEWallets();
        setEWalletsList(ewallets.data);
        setLoading(false);
    }

    return (
        <div className='t1'>
            <div className='page'>
                <div className="page-content footer-clear">
                    <Header />
                    <div className="">
                        <div className="mx-2 px-2 p-0 pt-3 rounded-m">
                            <div className="mx-2 py-4">
                                {ewalletsList && ewalletsList.map((item, index) => {
                                    return (
                                        <a id="nav-manual" className={`list-group-item flex justify-between ewallet-item p-2 px-4`} key={index} onClick={() => navigate(`/ewallet/${item.id}`)}>
                                            <div className="flex">
                                                <img src={item.icon} alt="" width="40px" className="icon icon-lg" />
                                                <div className='flex items-center ml-1'>{String(item.name).toLocaleUpperCase()}</div>
                                            </div>

                                            <i className={`fa fa-chevron-right flex items-center`}></i>
                                        </a>

                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <MenuNotification />
                <MenuSidebar />
            </div>
            {
                isLoading && <Loading />
            }
        </div>
    )
}

export default EWallets