import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MenuSidebar from '../components/MenuSidebar'
import authService from '../service/auth.service'

const initialValues = {
    username: "",
    password: "",
};

function SignIn() {
    const [values, setValues] = useState(initialValues);
    const navigate = useNavigate()

    const [validations, setValidations] = useState({
        username: "",
        password: "",
        refresh: "",
    })

    const {
        username: nameVal,
        password: passVal,
        refresh: refreshVal,
    } = validations

    const handleChange = (e) => {
        setValues(values => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            await authService.login(values);
            navigate('/');
        } catch (err) {
            console.log("err ==> ", err)
            let username_msg;
            let password_message;
            let refresh_msg;
            if (err.response && err.response.data && err.response.data.username) {
                username_msg = err.response.data.username;
            }
            if (err.response && err.response.data && err.response.data.refresh) {
                refresh_msg = err.response.data.refresh;
            }
            if (err.response && err.response.data && err.response.data.detail) {
                refresh_msg = err.response.data.detail;
            }
            if (err.response && err.response.data && err.response.data.password) {
                password_message = err.response.data.password;
            }
            setValidations({ ...validations, username: username_msg, password: password_message, refresh: refresh_msg });
        }
    }

    return (
        <div className="t3" >
            <div id="page">
                <div className="page-content pb-0">
                    <div data-card-height="cover-full" className="card mb-0 mx-0">
                        <div className="t1">
                            <Header />
                        </div>
                        <div className="card-center px-4">
                            <div>
                                <h1 className="font-23 font-700" style={{ color: '#ffc107' }}>Login</h1>
                            </div>
                            <p className="input-title mb-2 font-14" style={{ color: "#223651" }}>Mobile No / Username</p>
                            <div >

                                <div
                                    className={`input-style has-borders has-icon validate-field mb-2  ${values.username ? "input-style-active" : ""}`}>
                                    <input
                                        name="username"
                                        type="text"
                                        className={`form-control validate-name`}
                                        id="form1"
                                        placeholder="Username"
                                        onChange={handleChange}
                                    />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                    <div className={`${nameVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nameVal}</div>
                                </div>
                                {/* <div className={`input-style input-transparent no-borders has-icon validate-field mb-4  ${data.user ? "input-style-active" : ""}`}>
                                    <i className="ps-1 fa fa-user"></i>
                                    <input onChange={e => setData({ ...data, user: e.target.value })} type="name" className={`form-control validate-name`} id="form1a" placeholder="Username" />
                                    <label htmlFor="form1a" className="color-highlight">Username</label>
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div> */}
                                <p className="input-title mb-2 font-14" style={{ color: "#223651" }}>Password</p>
                                <div className={`input-style has-borders has-icon validate-field mb-4  ${values.password ? "input-style-active" : ""}`}>
                                    <input
                                        onChange={handleChange}
                                        name="password"
                                        type="password"
                                        className={`form-control validate-password`}
                                        id="form1a"
                                        placeholder="Password"
                                    />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                    <div className={`${passVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{passVal}</div>
                                    <div className={`${refreshVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{refreshVal}</div>
                                </div>
                                {/* <div className={`input-style input-transparent no-borders has-icon validate-field mb-4  ${data.password ? "input-style-active" : ""}`}>
                                    <i className="ps-1 fa fa-lock"></i>
                                    <input onChange={e => setData({ ...data, password: e.target.value })} type="password" className={`form-control validate-password`} id="form1a" placeholder="Password" />
                                    <label htmlFor="form1a" className="color-highlight">Password</label>
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div> */}
                                <div
                                    data-back-button
                                    className="btn btn-full btn_green mt-4"
                                    onClick={handleSubmit}
                                >
                                    Login
                                </div>
                                {/* <div className="row pt-3 mb-3">
                                    <div className="col-6 text-start font-11">
                                    </div>
                                    <div className="col-6 text-end font-11">
                                        <a className="color-white opacity-50" href=".#" onClick={e => {
                                            e.preventDefault()
                                            navigate("/signup")
                                        }}>Create Account</a>
                                    </div>
                                </div> */}
                                <p className="input-title my-3 text-center font-14" style={{ color: "#223651" }}>Do Not Have An Account Yet?</p>

                                <div
                                    data-back-button
                                    className="btn mx-auto btn-full btn_green register_btn mt-2"
                                    onClick={e => {
                                        e.preventDefault()
                                        navigate("/signup")
                                    }}
                                >
                                    Register
                                </div>

                                <p
                                    className="input-title my-3 text-center font-14"
                                    style={{ color: "#223651" }}
                                    onClick={e => {
                                        e.preventDefault()
                                        navigate("/forget-password")
                                    }}
                                >
                                    Forgot Password? Click Here
                                </p>
                            </div>
                        </div>
                        <div className="t1">
                            <Footer />
                            <MenuSidebar />
                        </div>
                        <div className="card-overlay bg-black"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn