import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import { AppContext } from '../App'
import authService from '../service/auth.service'
import Loading from '../components/Loading'

function SignUp2() {
    const [appData] = useContext(AppContext)
    const [code, setCode] = useState('')
    const [verifyVal, setVerifyVal] = useState('')
    const [waitTime, setWaitTime] = useState(0);
    // const [mobileNum, setMobileNum] = useState('');
    const [isGetting, setIsGetting] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate()

    const getTacCode = async () => {
        setIsGetting(true)
        try {
            const userId = appData.userId;
            const TacCode = await authService.getTac(userId);
            // setMobileNum(TacCode.data.recipient);
            if (!TacCode.data.wait) {
                setWaitTime(60);
            }
            if (TacCode.data.tac) {
                return TacCode.data.tac;
            }
        } catch (err) {
            console.log(err)
            if (err.response.data.wait) {
                setWaitTime(err.response.data.wait)
            }
        }
        setIsGetting(true)
    }

    const VerifyCode = async () => {
        setLoading(true);
        if (code) {
            try {
                const userId = appData.userId;
                await authService.verifyTac({ userId, code });
                navigate('/signup3');
            } catch (err) {
                console.log(err)
                if (err.response && err.response.data && err.response.data.tac) {
                    const username_msg = err.response.data.tac;
                    if (Number(String(username_msg).slice(-2)) < 0) {
                        navigate('/signup');
                    }
                    setVerifyVal(username_msg);
                }
                if (err.response && err.response.data && err.response.data.detail) {
                    const username_msg = err.response.data.detail;
                    setVerifyVal(username_msg);
                }
            }
        }
        setLoading(false);
    }

    const reSendCode = async () => {
        if (waitTime === 0) {
            await getTacCode(appData.userId);
            setWaitTime(60);
        }
    }

    useEffect(() => {
        if (appData && appData.userId && !isGetting) {
            getTacCode(appData.userId);
        }
    }, [appData.userId])

    useEffect(() => {
        if (waitTime > 0) {
            setTimeout(() => {
                setWaitTime(value => value - 1)
            }, 1000)
        }
    }, [waitTime])

    return (
        <div className='t3'>
            <div id="page">
                <div className="page-content pb-0">
                    <div data-card-height="cover-full" className="card mb-0">
                        <div className="card-center">
                            <div className="text-center">
                                <p className="font-600 color-highlight mb-1 font-16">Verify your Identity</p>
                                <h1 className="font-26 color-white">One Time Passcode</h1>
                            </div>
                            <div className="content px-4">
                                <div className="text-center mx-n3">
                                    <OtpInput
                                        containerStyle={{ marginTop: "1.5rem", columnGap: "10px", justifyContent: "space-between" }}
                                        inputStyle={{ width: "50px", height: "50px", borderRadius: "8px", color: "black", fontSize: "2rem", fontWeight: "bold" }}
                                        value={code}
                                        onChange={setCode}
                                        numInputs={5}
                                        separator={<span>-</span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <div
                                        className={`text-center mt-2 ${(verifyVal === '') ? 'valid-feedback' : 'invalid-feedback'} `}
                                    >
                                        {verifyVal}
                                    </div>
                                    <div>
                                        {waitTime > 0 && <div className="flex items-center justify-center text-red text-sm mt-3">
                                            <div>0{Math.floor(waitTime / 60)}:</div>
                                            <div>{(waitTime < 10 ? '0' : '') + waitTime % 60}</div>
                                        </div>}
                                    </div>
                                </div>
                                <p
                                    className="text-center my-4 font-13 color-white"
                                >
                                    Didn't get your code?
                                    <span
                                        className={`resend-code ${waitTime > 1 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                        style={{ color: "#D4A948" }}
                                        onClick={reSendCode}
                                    >
                                        Resend Code
                                    </span>
                                </p>
                                <div
                                    data-back-button
                                    className="btn btn-full font-600 font-15 gradient-highlight mt-4 rounded-xs"
                                    onClick={VerifyCode}
                                >
                                    VERIFY ACCOUNT
                                </div>
                            </div>
                        </div>
                        <div className="card-overlay bg-black opacity-85"></div>
                    </div>
                </div>
            </div>
            {
                isLoading && <Loading />
            }
        </div>
    )
}

export default SignUp2