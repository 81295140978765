import React from 'react'

const categories = [
    {
      id: 1,
      key: "news",
      name: "News",
    },
    {
      id: 2,
      key: "",
      name: "All",
    },
    {
      id: 3,
      key: "casino",
      name: "Casino",
    },
    {
      id: 4,
      key: "slots",
      name: "Slots",
    },
    {
      id: 5,
      key: "sportsbook",
      name: "Sports"
    },
  ];

function Tabs({ tablist, setTabNum, active, setActive }) {

    return (
        <div className="tab-controls tabs-medium tabs-rounded d-flex flex-column w-25" data-highlight="bg-highlight">
            {categories.map((category, index) => (
                <a
                    data-active={active === index ? true : false}
                    className={`${active === index ? "bg-highlight" : ""}`}
                    key={index}
                    onClick={() => {
                        setTabNum(category.key);
                        setActive(index);
                    }}
                >
                    {category.name}
                </a>
            ))}
        </div>
    )
}

export default Tabs