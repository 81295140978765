import React, { useContext } from 'react'
import { UserContext } from '../App'
import PaymentGateway from '../components/ManualTransfer'
import OnlineTransfer from '../components/OnlineTransfer'
import ReloadCard from '../components/ReloadCard'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MenuNotification from '../components/MenuNotification'
import MenuSidebar from '../components/MenuSidebar'
import ManualTransfer from '../components/ManualTransfer'

function Deposit({ type }) {
    const { mode } = useContext(UserContext)
    return (
        <div className='t1'>
            <div className='page'>
                <div className="page-content footer-clear">
                    <Header />
                    <div className="">
                        <div className="mt-4 mx-2 px-2 p-0 pt-3 rounded-m h-scroll">
                            {type === "/manual-transfer" && <ManualTransfer />}
                            {type === "/online-transfer" && <OnlineTransfer />}
                            {/* {type === "/reload-card" && <ReloadCard />} */}
                            
                        </div>
                        {mode === "light-mode" && <div className={`card-overlay rounded-0 m-0 bg-black-light opacity-70`}></div>}
                    </div>
                </div>
                <Footer />
                <MenuNotification />
                <MenuSidebar />
            </div>
        </div>
    )
}

export default Deposit