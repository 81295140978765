import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { AppContext } from '../App'
import Header from '../components/Header'

import authService from '../service/auth.service'
import tokenService from '../service/token.service'

const initialValues = {
    username: "",
    password: "",
    password2: "",
    cc: "60",
    mobile: "",
    referrer_code: ""
};

function SignUp() {
    const navigate = useNavigate()
    const location = useLocation();
    const [appData, setAppData] = useContext(AppContext)
    const [values, setValues] = useState(initialValues);
    const [validations, setValidations] = useState({
        username: "",
        password: "",
        password2: "",
        cc: "",
        mobile: "",
        non_field_errors: "",
        referrer_code: ""
    })

    const [readOnly, setReadOnly] = useState(false)

    const {
        username: nameVal,
        password: passVal,
        password2: pass2Val,
        cc: ccVal,
        mobile: mobileVal,
        non_field_errors: nonVal,
        referrer_code: referrerVal
    } = validations

    const handleChange = (e) => {
        setValues(values => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async () => {
        // if (!terms) return;

        let response;
        try {
            response = await authService.register(values);
            if (response && response.data && response.data.id) {
                // setUserId(response.data.id);
                setAppData({ ...appData, userId: response.data.id })
                tokenService.setRegisterUser(values);
            }
            navigate('/signup2');
        } catch (err) {
            console.log("err => ", err, values)
            let username_msg;
            let cc_message;
            let mobile_message;
            let password_message;
            let password2_message;
            let non_field_errors_message;
            let referral_message;

            if (err.response && err.response.data && err.response.data.username) {
                username_msg = err.response.data.username;
            }
            if (err.response && err.response.data && err.response.data.cc) {
                cc_message = err.response.data.cc;
            }
            if (err.response && err.response.data && err.response.data.mobile) {
                mobile_message = err.response.data.mobile;
            }
            if (err.response && err.response.data && err.response.data.password) {
                password_message = err.response.data.password;
            }
            if (err.response && err.response.data && err.response.data.password2) {
                password2_message = err.response.data.password2;
            }
            if (err.response && err.response.data && err.response.data.referrer_code) {
                referral_message = err.response.data.referrer_code;
            }
            if (err.response && err.response.data && err.response.data.non_field_errors) {
                non_field_errors_message = err.response.data.non_field_errors;
            }
            setValidations({
                ...validations, username: username_msg, password: password_message, password2: password2_message, mobile: mobile_message, cc: cc_message,
                non_field_errors: non_field_errors_message, referrer_code: referral_message
            });
        }
    }

    const getReferralCode = () => {
        const searchParams = new URLSearchParams(location.search);
        const myParam = searchParams.get('referrer');
        const localStorageCode = localStorage.getItem('referralCode')
        if (myParam || localStorageCode) {
            setValues(values => {
                return { ...values, referrer_code: myParam || localStorageCode }
            })
            localStorage.removeItem('referralCode')
            setReadOnly(true)
        } else {
            setReadOnly(false)
        }
    }

    useEffect(() => {
        getReferralCode()
    }, [])

    return (
        <div className="t3" >
            <div id="page">
                <div className="page-content pb-0">
                    <div data-card-height="cover-full" className="card mb-0 mx-0">
                        <div className="t1">
                            <Header />
                        </div>
                        <div className="card-center">
                            {/* <div className="text-left" style={{paddingLeft:"15px"}}>
                                <h1 className="font-40 color-white">Sign Up</h1>
                            </div> */}
                            <div className="content">

                                <p className="input-title my-1 font-14" style={{ color: "#223651" }}>Full Name * :</p>
                                <div className={`input-style has-borders no-icon validate-field mb-4  ${values.user ? "input-style-active" : ""}`}>
                                    <input
                                        name="username"
                                        onChange={handleChange}
                                        type="text"
                                        className={`form-control validate-name`}
                                        id="form1a"
                                        placeholder="Username"
                                    />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <div className={`${nameVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nameVal}</div>
                                </div>

                                <p className="input-title my-1 font-14" style={{ color: "#223651" }}>Mobile No * :</p>
                                <PhoneInput
                                    inputStyle={{ width: "100%", borderRadius: '0px', height: "40px" }}
                                    containerStyle={{ marginBottom: "10px" }}
                                    buttonStyle={{ borderRadius: '0' }}
                                    country={'my'}
                                    onlyCountries={['my', 'sg']}
                                    localization={{ my: 'Malaysia', sg: 'Singapore' }}
                                    onChange={(phone, country) => {
                                        const reducedPhone = phone.replace(country.dialCode, '',);
                                        setValues({ ...values, cc: country.dialCode, mobile: reducedPhone })
                                    }}
                                />
                                <div className={`${ccVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{ccVal}</div>
                                <div className={`${mobileVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{mobileVal}</div>

                                <p className="input-title my-1 font-14" style={{ color: "#223651" }}>Password * :</p>
                                <div className="input-style has-borders no-icon validate-field mb-4">
                                    <input
                                        name="password"
                                        type="password"
                                        className="form-control validate-text"
                                        id="form3"
                                        placeholder="Password"
                                        onChange={handleChange}
                                    />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <div className={`${passVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{passVal}</div>
                                </div>
                                {/* <div className={`input-style has-borders no-icon validate-field mb-4  ${values.password ? "input-style-active" : ""}`}>
                                    <i className="ps-1 fa fa-lock"></i>
                                    <input onChange={e => setValues({ ...values, password: e.target.value })} type="password" className={`form-control validate-password`} id="form1a" placeholder="Password" />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div> */}
                                <p className="input-title my-1 font-14" style={{ color: "#223651" }}>Confirm Password * :</p>

                                <div className={`input-style has-borders no-icon validate-field mb-4  ${values.password2 ? "input-style-active" : ""}`}>
                                    <input
                                        onChange={handleChange}
                                        type="password"
                                        name="password2"
                                        className={`form-control validate-password`}
                                        id="form1a"
                                        placeholder="Confirm Password"
                                    />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <div className={`${pass2Val === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{pass2Val}</div>
                                </div>

                                <p className="input-title my-1 font-14" style={{ color: "#223651" }}>Referral Code * :</p>

                                <div className={`input-style has-borders no-icon validate-field mb-4  ${values.referrer_code ? "input-style-active" : ""}`}>
                                    <input
                                        onChange={handleChange}
                                        type="text"
                                        name="referrer_code"
                                        className={`form-control validate-password`}
                                        id="form1a"
                                        placeholder="Referral Code"
                                        readOnly={readOnly}
                                        autoCapitalize="none"
                                    />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <div className={`${referrerVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{referrerVal}</div>
                                    <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div>
                                </div>


                                <a href=".#" data-back-button className="btn mx-auto w-100 btn-full btn_green register_btn mt-4" onClick={e => {
                                    e.preventDefault()
                                    handleSubmit()
                                }}>REGISTER</a>

                                <p className="input-title text-center my-1 font-14" style={{ color: "#223651" }}>Already Have An Account?</p>
                                <p className="input-title text-center my-1 font-14" style={{ color: "#223651" }} onClick={e => {
                                    e.preventDefault()
                                    navigate("/signin")
                                }}>Login Here</p>
                            </div>
                        </div>
                        {/* <div className="t1">
                            <Footer />
                        </div> */}
                        <div className="card-overlay bg-black"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp