import React, { useState, useEffect } from 'react'
import { Modal, Upload, message, Button, Radio, Space } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import userService from '../service/user.service';
import Loading from './Loading';

const amountlist = [
    { id: 1, value: "50" },
    { id: 2, value: "100" },
    { id: 3, value: "200" },
    { id: 4, value: "300" },
    { id: 5, value: "500" },
];

const initialValues = {
    ttype: "IB",
    amount: "",
    proof: undefined,
    merchant_bank_account: "",
};

const init_validation = {
    amount: "",
    merchant_bank_account: "",
    non_field_errors: "",
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
};

function ManualTransfer() {
    const [values, setValues] = useState(initialValues);
    const [banks, setBanks] = useState([]);
    const [radioValue, setRadioValue] = useState(null);
    const [merchantBanks, setMerchantBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const [validations, setValidations] = useState(init_validation);
    const [isLoading, setAPILoading] = useState(false);
    const [txnId, setTxnId] = useState(false);
    const [isTxnModal, setIsTxnModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [promotionList, setPromotionList] = useState([]);
    const [selectedTnC, setSelectedTnC] = useState({
        title: "",
        description: "",
    });

    const navigate = useNavigate();

    const {
        amount: amountVal,
        merchant_bank_account: bank_Val,
        non_field_errors: nonVal,
    } = validations;

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value);
        setValues((values) => {
            return { ...values, promotion: e.target.value };
        });
    };


    const getBankList = async () => {
        setAPILoading(true);
        const getBanks = await userService.getBanks();
        setBanks(getBanks.data);
        setAPILoading(false);
    }

    const getMerchantBanklist = async () => {
        setAPILoading(true);
        const getMerchantlist = await userService.getMerchantBanks();
        setMerchantBanks(getMerchantlist.data);
        setAPILoading(false);
    };

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Bank Account Number Copied',
        });
    };

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === "") {
            setValues((values) => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    };

    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (url) => {
                // console.log("url ===> ", url, info.file.name)
                const proofData = {};
                proofData.title = info.file.name;
                proofData.base64 = url;
                setValues({ ...values, proof: proofData });
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const getPromotions = async () => {
        const promotionListing = await userService.getPromotions();

        const nonObj = {
            id: 0,
            title: "No promotion",
            active: true
        }

        promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);

        setPromotionList(promotionListing.data);
        setSelectedTnC(promotionListing.data[0]);
        setRadioValue(promotionListing.data[0].id);
    };

    const handleDeposit = async (e) => {
        e.preventDefault();
        setAPILoading(true);

        console.log("values => ", values);

        let response;
        try {
            response = await userService.createDeposit(values);
            setTxnId(response.data.id);
            setIsTxnModal(true);
            setTimeout(() => {
                navigate("/account");
            }, 5000);
            setImageUrl();
            setValues(initialValues);
            setValidations(init_validation);
        } catch (err) {
            console.log("err => ", err);
            let non_msg;
            let amount_msg;
            let bank_msg;

            if (err && err.response && err.response.data && err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err && err.response && err.response.data && err.response.data.merchant_bank_account) {
                bank_msg = err.response.data.merchant_bank_account;
            }
            if (err && err.response && err.response.data && err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }

            setValidations({
                ...validations,
                amount: amount_msg,
                merchant_bank_account: bank_msg,
                non_field_errors: non_msg,
            });
        }
        setAPILoading(false);
    };

    useEffect(() => {
        getPromotions();
        getBankList();
        getMerchantBanklist();
    }, []);

    return (
        <div className="content m-0">
            <div className="tabs tabs-pill" id="tab-group-2">
                <div className="mt-3"></div>
                <div className="collapse show" id="tab-4" data-bs-parent="#tab-group-2">
                    <div className="banks-container mb-3">
                        <div className="banks-wrap">
                            {merchantBanks.map((bank, index) => (
                                <div
                                    className={`col-4 bank-item ${selectedBank && selectedBank.id === bank.id
                                        ? "selected-bank"
                                        : ""
                                        }`}
                                    key={index}
                                    onClick={() => {
                                        setValues({ ...values, merchant_bank_account: bank.id });
                                        setSelectedBank(bank);
                                    }}
                                >
                                    <img
                                        src={
                                            banks.length > 0 &&
                                                banks.find((bk) => bk.id === bank.bank).icon
                                                ? banks.find((bk) => bk.id === bank.bank).icon
                                                : "/images/game01.png"
                                        }
                                        width={50}
                                        alt=""
                                    />
                                    {/* <img src={bank.icon ? bank.icon : '/images/game01.png'} width={50} alt='' /> */}
                                    <div style={{ fontSize: "12px" }}>{bank.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={`${bank_Val === "" ? "valid-feedback" : "invalid-feedback"
                            } `}
                    >
                        {bank_Val}
                    </div>

                    {selectedBank && (
                        <div className="banks-info mb-3">
                            <table>
                                <tr>
                                    <td>Bank Account Name</td>
                                    <td>{selectedBank.name}</td>
                                </tr>
                                <tr>
                                    <td>Bank Account Number</td>
                                    <td>{selectedBank.number}</td>
                                    <td
                                        className="copy-btn"
                                        onClick={() => success(selectedBank.number)}
                                    >
                                        COPY
                                    </td>
                                </tr>
                            </table>
                        </div>
                    )}

                    <div className="form-custom form-label form-icon mb-3 bg-transparent">
                        <input
                            name="amount"
                            type="number"
                            className="form-control rounded-xxs mb-3"
                            id="c1a"
                            placeholder="Amount"
                            onChange={handleNumChange}
                            value={values.amount}
                        />
                        <label htmlFor="c1a" className="color-theme">Amount</label>
                        <span>(required)</span>
                        <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between mb-3">
                        {amountlist.map((item, i) => (
                            <button
                                key={i}
                                type="button"
                                className="btn-full btn border-blue-dark color-blue-dark text-center mb-2"
                                onClick={() => setValues({ ...values, amount: item.value })}
                            >
                                {item.value}
                            </button>
                        ))}
                    </div>
                    <div className="w-full">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </div>
                    <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div>
                    <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
                        <Space direction="vertical">
                            {(promotionList && promotionList.length > 0) && promotionList.filter(item => item.active).map(item => {
                                return (
                                    <Radio value={item.id} key={item.id} onChange={() => setSelectedTnC(item)} onClick={() => {
                                        // setModalOpen(true);
                                        if (item.id !== 0) {
                                            setValues(values => {
                                                return { ...values, promotions_applied: item.id }
                                            })
                                        } else {
                                            delete values.promotions_applied;
                                        }
                                    }}>{item.title}</Radio>
                                )
                            })}
                        </Space>
                    </Radio.Group>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <button className="btn btn-xl gradient-highlight shadow-bg shadow-bg-s mt-4 ms-auto"
                    onClick={handleDeposit}
                >
                    Deposit
                </button>
            </div>
            <div className="d-flex align-items-center flex-column my-3">
                <i className="fa-brands fa-whatsapp color-dark-green fa-3x" style={{ color: "#25D366" }}></i>
                <h3 style={{ color: "#223651" }}>Live chat support</h3>
            </div>
            <Modal
                title="Congratulations!"
                style={{ top: "50px" }}
                open={isTxnModal}
                onCancel={() => setIsTxnModal(false)}
                footer={[]}
            >
                <div className="text-lg font-semibold">Deposit is successful.</div>
                <div className="font-semibold mb-3">
                    Transaction Id: <span className="text-lime-700">{txnId}</span>
                </div>
                <div className="w-full flex justify-end gap-3 -mb-3">
                    <Button onClick={() => setIsTxnModal(false)}>Cancel</Button>
                    <Button
                        className="bg-sky-600"
                        onClick={() => navigate("/account")}
                    >
                        OK
                    </Button>
                </div>
            </Modal>

            <Modal
                title={selectedTnC.title}
                centered
                open={modalOpen}
                footer={[]}
                onCancel={() => setModalOpen(false)}
            >
                <div className="text-lg font-semibold">Terms & Conditions</div>

                <div
                    className="mt-2"
                    dangerouslySetInnerHTML={{ __html: selectedTnC.description }}
                />
            </Modal>
            {
                isLoading && <Loading />
            }
        </div>
    )
}

export default ManualTransfer