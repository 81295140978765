import React, { useState, useEffect } from 'react'
import Swal from "sweetalert2";
import { Empty, Tabs } from "antd";
import Transactions from '../pages/TransactionHistory'
import Bets from '../pages/BetHistory'
import Header from './Header'
import Footer from './Footer'
import MenuSidebar from './MenuSidebar'
import userService from '../service/user.service'
import Loading from './Loading';

function HistoryTabs() {
    const [active, setActive] = useState(1)
    const [orders, setOrders] = useState([]);
    const [transfers, setTransfers] = useState([]);
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noItems, setNoItems] = useState(false);
    const [userId, setUserId] = useState("");
    const [bonuses, setBonuses] = useState([]);
    const [deleteTxn, setDeleteTxn] = useState(false);

    const getTransactions = async (userId) => {
        setLoading(true);
        try {
            const orderData = await userService.getOrders(userId);
            // const orderData = [];
            const transferData = await userService.getTransfers(userId);
            // const transferData = [];
            // const bonusData = await userService.getBonues(userId);
            if (!orderData.data.length && !transferData.data.length) {
                setNoItems(true);
            }
            if (orderData.data) {
                setOrders(orderData.data);
            }
            if (transferData.data) {
                setTransfers(transferData.data);
            }
            // if (bonusData.data) {
            //   setBonuses(bonusData.data);
            // }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    const getWalletInfo = async () => {
        setLoading(true);
        try {
            const wallet = await userService.getBalance();
            setWallet(wallet.data);
            console.log(wallet.data);
            getTransactions(wallet.data.user.id);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async (txnId, type) => {
        console.log("+++", txnId, type);
        try {
            setLoading(true);
            const result = await userService.deleteActor(userId, txnId);

            if (type === "order") {
                const newItems = [...orders];
                const filterItems = newItems.map((item) => ({
                    ...item,
                    state: item.id === txnId ? "deleted" : item.state,
                }));
                setOrders(filterItems);
            }
            if (type === "transfer") {
                const newItems = [...transfers];
                const filterItems = newItems.map((item) => ({
                    ...item,
                    state: item.id === txnId ? "deleted" : item.state,
                }));
                setTransfers(filterItems);
            }
            if (type === "bonus") {
                const newItems = [...bonuses];
                const filterItems = newItems.map((item) => ({
                    ...item,
                    state: item.id === txnId ? "deleted" : item.state,
                }));
                setBonuses(filterItems);
            }

            setDeleteTxn(!deleteTxn);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Swal.fire({
                icon: "failed",
                text: err,
            });
        }
    };

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        if (!wallet) {
            getWalletInfo();
        }
    }, [wallet]);

    const items = [
        {
            key: "1",
            label: `Orders & Bonuses`,
            children: (
                <div>
                    {orders.length > 0 ? (
                        <>
                            {/* {orders.map((order) => (
                    <OrderItem
                      item={order} 
                      key={order.id}
                      handleDelete={handleDelete}
                      type="order"
                    />
                  ))} */}
                            <Transactions
                                orders={orders}
                                handleDelete={handleDelete}
                                type="order"
                            />
                        </>
                    ) : (
                        <>
                            {
                                !loading && noItems &&
                                <Empty description="No items found!" />
                            }
                        </>
                    )}
                </div>
            ),
        },
        {
            key: "2",
            label: `Transfers`,
            children: (
                <div>
                    {transfers.length > 0 ? (
                        <>
                            {/* {transfers.map((order) => (
                    <OrderItem order={order} key={order.id} />
                  ))} */}
                            <Transactions
                                orders={transfers}
                                handleDelete={handleDelete}
                                type="transfer"
                            />
                        </>
                    ) : (
                        <>
                            <Empty description="No items found!" />
                        </>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div className='t1'>
            <div className='page'>
                <div className="page-content footer-clear">
                    <Header />
                    <div className="content t3" id="tab-group-1" style={{ margin: "20px 15px 20px 15px" }}>
                        <div className="tab-controls tabs-medium tabs-rounded" data-highlight="bg-highlight" style={{ padding: "6px" }}>
                            <a href="/" style={{ margin: 0, width: "50%" }} data-active={active === 1 ? true : false} className={`${active === 1 ? "bg-highlight" : ""}`} data-bs-toggle="collapse" data-bs-target="#tab-1" onClick={() => setActive(1)}>Transaction History</a>
                            <a href="/" style={{ margin: 0, width: "50%" }} data-active={active === 2 ? true : false} className={`${active === 2 ? "bg-highlight" : ""}`} data-bs-toggle="collapse" data-bs-target="#tab-2" onClick={() => setActive(2)}>Bet History</a>
                        </div>
                        <div className="clearfix mb-3"></div>
                        <div data-bs-parent="#tab-group-1" className="collapse show" id="tab-1">
                            <Tabs
                                defaultActiveKey="1"
                                items={items}
                                onChange={onChange}
                            />
                            {/* <Transactions
                                orders={orders}
                                handleDelete={handleDelete}
                                type="order"
                            /> */}
                        </div>
                        <div data-bs-parent="#tab-group-1" className="collapse" id="tab-2">
                            <Bets />
                        </div>
                    </div>
                </div>
                <Footer />
                <MenuSidebar />
            </div>
            {
                loading && <Loading />
            }
        </div>
    )
}

export default HistoryTabs