import React from 'react'
import { Space, Spin } from 'antd';

function Loading() {
    return (
        <div className="fixed w-screen h-screen top-0 left-0 flex justify-center zIndex100 bg-01">
            <Space>
                <Spin size="large" />
            </Space>
        </div>
    )
}

export default Loading