import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { AppContext } from '../App'
import authService from '../service/auth.service'
import tokenService from '../service/token.service'

const initialValues = {
    bank: 1,
    account_number: "",
    account_name: "",
};

function SignUp3() {
    const navigate = useNavigate()
    const [appData] = useContext(AppContext);
    const [values, setValues] = useState(initialValues);
    const [banks, setBanks] = useState([]);
    const [validations, setValidations] = useState({
        bank: "",
        account_number: "",
        account_name: "",
    })

    const {
        bank: bankVal,
        account_name: nameVal,
        account_number: phoneVal,
    } = validations

    const getBanks = async () => {
        const banklist = await authService.getBanklist();
        let formattedbanklist = [];
        banklist.data.map((bk) => {
            if (bk) {
                formattedbanklist.push({
                    value: bk.id,
                    label: bk.name,
                    icon: bk.icon,
                    number: bk.number
                });
            }

            return formattedbanklist;
        })

        setBanks(formattedbanklist);

    }
    const handleSave = async e => {
        e.preventDefault();

        if (!appData.userId) return;

        try {
            const userId = appData.userId;
            await authService.activate({ userId, values });
            const uservalue = tokenService.getRegisterUser();
            await authService.login({ username: uservalue.username, password: uservalue.password });
            navigate('/');
        } catch (err) {
            console.log("err => ", err);
            let bank_msg;
            let account_name_msg;
            let account_number_msg;
            if (err.response && err.response.data && err.response.data.bank) {
                bank_msg = err.response.data.bank;
            }
            if (err.response && err.response.data && err.response.data.account_name) {
                account_name_msg = err.response.data.account_name;
            }
            if (err.response && err.response.data && err.response.data.account_number) {
                account_number_msg = err.response.data.account_number;
            }
            if (err.response && err.response.data && err.response.data.non_field_errors) {
                account_number_msg = err.response.data.non_field_errors;
            }

            setValidations({ ...validations, bank: bank_msg, account_number: account_number_msg, account_name: account_name_msg });
        }
    }

    useEffect(() => {
        getBanks();
    }, [])

    const handleChange = (e) => {
        setValues(values => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleBKAccChange = (e) => {
        const RE = /[0-9\b]$/;
        if (RE.test(e.currentTarget.value) || e.currentTarget.value === '') {
            setValues({ ...values, account_number: e.currentTarget.value });
        }
    }

    const handleBKChange = (e) => {
        setValues(values => {
            return { ...values, bank: e.value };
        });
    };

    return (
        <div className="t3" >
            <div id="page">
                <div className="page-content pb-0">
                    <div data-card-height="cover-full" className="card mb-0">
                        <div className="card-center">
                            <div className="text-center">
                                <h1 className="font-40 color-white">Bank Details</h1>
                            </div>
                            <div className="content px-4">
                                <div className={`mb-3`}>
                                    <Select
                                        options={banks}
                                        formatOptionLabel={bk => (
                                            <div className="flex items-center gap-1" key={bk.id}>
                                                <img src={bk.icon} alt="" className="w-10 h-10" />
                                                <span className='text-black'>{bk.label}</span>
                                            </div>
                                        )}
                                        onChange={handleBKChange}
                                        aria-label="Floating label select example"
                                        placeholder="--Select a Bank--"
                                    />
                                    <div className={`${bankVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{bankVal}</div>
                                </div>
                                <div className={`input-style has-borders has-icon validate-field mb-4  ${values.account_name ? "input-style-active" : ""}`}>
                                    <input
                                        onChange={handleChange}
                                        name="account_name"
                                        type="text"
                                        className={`form-control validate-name`}
                                        id="form1a"
                                        placeholder="Username"
                                    />
                                    <label htmlFor="form1a" className="color-highlight ">Username</label>
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                    <div className={`${nameVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nameVal}</div>
                                </div>
                                <div className={`input-style has-borders has-icon validate-field mb-4  ${values.account ? "input-style-active" : ""}`}>
                                    <input
                                        onChange={handleBKAccChange}
                                        name="account_number"
                                        type="text"
                                        className={`form-control validate-name`}
                                        id="form1a"
                                        placeholder="Bank Account Number"
                                    />
                                    <label htmlFor="form1a" className="color-highlight">Bank Account Number</label>
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                    <div className={`${phoneVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{phoneVal}</div>
                                </div>
                                <div
                                    data-back-button
                                    className="btn btn-full btn-l font-600 font-15 gradient-highlight mt-4 rounded-s"
                                    onClick={handleSave}
                                >
                                    Save And Continue
                                </div>
                            </div>
                        </div>
                        <div className="card-overlay bg-black opacity-85"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp3