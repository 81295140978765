import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUp2 from "./pages/SignUp2";
import SignUp3 from "./pages/SignUp3";
import Forget from "./pages/Forget";
import VerifyTac from "./pages/VerifyTac";

const AuthLayout = () => {
  return (
    <div>
      <Routes>
        <Route path="/signin" exact element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/signup2" element={<SignUp2 />} />
        <Route exact path="/signup3" element={<SignUp3 />} />
        <Route exact path="/forget-password" element={<Forget />} />
        <Route exact path="/verify-tac" element={<VerifyTac />} />
        <Route path="/*" element={<SignIn />} />
      </Routes>
    </div>
  );
};

export default AuthLayout;
