import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import AuthService from "../service/auth.service";

export default function BalanceSection({
  hideQr,
  walletInfo,
  startedGameList,
  StopProduct,
  setStartedGameList
}) {
  const [currentUser, setCurrentUser] = useState(null);
  // const [count, setCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();

  const handleRefresh = () => {
    setRefreshing(true); // Start the refresh animation
    setTimeout(() => {
      setRefreshing(false); // Stop the refresh animation after 1 second (adjust as needed)
    }, 1000);
    navigate("/"); // Add your refresh logic here
  };

  // const getNotificationInfo = async () => {
  //   const getNotification = await UserService.getNotificationCount();
    // setCount(getNotification?.data?.count);
  // };

  

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      // getNotificationInfo();
    }
  }, [localStorage.getItem("user")]);

  return (
    <div
      className="balance-container p-2 d-flex justify-between m-2 mt-0"
      style={{
        border: "1px solid rgb(92, 149, 224)",
        background: "linear-gradient(to bottom, #5D9CEC, #4A89DC) !important"

      }}
    >
      {hideQr ? (
        ""
      ) : (
        <div className="w-1/5">
          <Icon
            style={{color:"#e8b22a"}}
            icon="grommet-icons:qr"
            fontSize={36}
            className="mr-10 cursor-pointer qr-icon"
            onClick={() => navigate("/referral")}
          />
        </div>
      )}
      <div className="d-flex items-center justify-center gap-2 balance-account w-2/5">
        <span className="" style={{color:"#e8b22a",fontWeight:"bold", fontSize:"15px"}}>MYR</span>
        <div
          className={`d-flex flex-col items-end text-stone-900`}
          style={{ fontWeight: "bold" }}
        >
          <h5>Cash</h5>
          <h5>{(currentUser &&  walletInfo) ? walletInfo.balance : "0.00"}</h5>
        </div>
      </div>
      <div className="d-flex items-center justify-center gap-2 balance-account w-2/5">
        <span className="" style={{color:"#e8b22a",fontWeight:"bold", fontSize:"15px"}}>MYR</span>
        <div
          className={`d-flex flex-col items-end text-stone-900`}
          style={{ fontWeight: "bold" }}
        >
          <h5>Chips</h5>
          <h5>{(currentUser && walletInfo) ? walletInfo.chips_balance : "0.00"}</h5>
        </div>
      </div>
      <div className="w-1/5">
          <Icon
            style={{color:"#e8b22a"}}
            icon="ic:outline-refresh"
            fontSize={36}
            className={`mr-10 cursor-pointer qr-icon ${refreshing ? "rotate-refresh" : ""}`}
            onClick={async (e) => {
              startedGameList.forEach(startedGame => {
                StopProduct(startedGame.id)
              })
              setStartedGameList([]);
              await handleRefresh();
            }}
          />
        </div>
    </div>
  );
}
