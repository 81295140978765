import React from 'react'

function Bets() {
    return (
        <div id="menu-notifications"
            className="rounded-m d-block mx-2"
        // style={{ backgroundColor: "#fff" }}
        >
            <div className="menu-size" style={{ height: "75vh" }}>
                <div className="content pb-2">
                    <a href="/" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1 color_yellow">Meg888 - Slot 1 Bet $100 Win $100</h5>
                            <p className="mb-0 font-11 opacity-70 color-white">14th March 03:14 AM</p>
                        </div>
                    </a>
                    <div className="divider my-2 opacity-50"></div>
                    <a href="/" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1 color_yellow">Meg888 - Slot 1 Bet $100 Win $100</h5>
                            <p className="mb-0 font-11 opacity-70 color-white">14th March 03:14 AM</p>
                        </div>
                    </a>
                    <div className="divider my-2 opacity-50"></div>
                    <a href="/" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1 color_yellow">Meg888 - Slot 1 Bet $100 Lose $10</h5>
                            <p className="mb-0 font-11 opacity-70 color-white">14th March 03:14 AM</p>
                        </div>
                    </a>
                    <div className="divider my-2 opacity-50"></div>
                    <a href="/" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1 color_yellow">Meg888 - Slot 1 Bet $100 Win $100</h5>
                            <p className="mb-0 font-11 opacity-70 color-white">14th March 03:14 AM</p>
                        </div>
                    </a>
                    <div className="divider my-2"></div>
                </div>
            </div>
        </div>
    )
}

export default Bets