import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Redirect from "./Redirect";
import authService from "./service/auth.service";
import Notifications from "./pages/Notifications";
import HistoryTabs from "./components/HistoryTabs";
import Account from "./pages/Account";
import Deposit from "./pages/Deposit";
import EWallets from "./pages/EWallets";
import EWallet from "./pages/EWallet";
import Withdraw from "./pages/Withdraw";
import Promotions from "./pages/Promotions";
import Settings from "./components/Settings";
import DepositNew from "./components/DepositNew";
import Home from "./pages/Home";
import userService from "./service/user.service";
import GameAccounts from "./pages/GameAccount";

const MainLayout = () => {
    const [ewalletsList, setEWalletsList] = useState([]);
    const [isRefresh, setRefresh] = useState(false);
    const [walletInfo, setWalletInfo] = useState(null);

  const getMerchantEWalletslist = async () => {
    const ewallets = await userService.getMerchantEWallets();
    setEWalletsList(ewallets.data);
  };

    const getWalletInfo = async () => {
        setRefresh(true);
        try {
          const getWallet = await userService.getBalance();
          setWalletInfo(getWallet.data);
        } catch (err) {
          console.log(err);
        }
        setRefresh(false);
      };

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      getWalletInfo();
    }
  }, [localStorage.getItem("user")]);

  return (
    <div>
      <Routes>
        <Route exact path="/notifications" element={<Notifications />} />
        <Route exact path="/history" element={<HistoryTabs />} />
        <Route exact path="/account" element={<Account />} />
        <Route
          exact
          path="/manual-transfer"
          element={<Deposit type={"/manual-transfer"} />}
        />
        <Route
          exact
          path="/online-transfer"
          element={<Deposit type={"/online-transfer"} />}
        />
        <Route exact path="/ewallet-transfer" element={<EWallets />} />
        <Route
          path="/ewallet/:id"
          element={
            <EWallet
              ewalletsList={ewalletsList}
              getMerchantEWalletslist={getMerchantEWalletslist}
            />
          }
        />
        <Route
          exact
          path="/reload-card"
          element={<Deposit type={"/reload-card"} />}
        />
        <Route
          exact
          path="/withdraw"
          element={<Withdraw walletInfo={walletInfo} />}
        />
        <Route exact path="/promotions" element={<Promotions />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/deposit" element={<DepositNew />} />
        <Route exact path="/gameaccount" element={<GameAccounts walletInfo={walletInfo} />} />
        <Route
          exact
          path="/"
          element={
            <Home getWalletInfo={getWalletInfo} walletInfo={walletInfo} />
          }
        />
        <Route path="/*" element={<Redirect />} />
      </Routes>
    </div>
  );
};

export default MainLayout;
