import React from "react";

function Header() {
  return (
    <div className="pt-1" style={{ zIndex: "111" }}>
      <div className="page-title d-flex justify-content-end my-0">
        <div className="align-self-center me-auto t3 custom-icon">
          <a
            href=".#"
            data-bs-toggle="offcanvas"
            data-bs-target="#menu-sidebar"
            data-menu="menu-main"
          >
            {" "}
            <i className="fa-solid fa-bars-staggered"></i>
          </a>
          <img src={require("../images/logo.png")} alt="" width="154px" />
        </div>
        <div className="align-self-center currency_icon">
          <a href=".#">
            <img src={require("../images/icon-livechat.png")} alt="" />
          </a>
          <a href=".#">
            <img src={require("../images/icon-mprofile.png")} alt="" />
          </a>
        </div>
        {/* <div className="align-self-center">
                    <a href=".#"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#menu-notifications"
                        onClick={e=>{
                            e.preventDefault()
                            navigate("/notifications")
                        }}
                        className="icon gradient-blue color-white shadow-bg shadow-bg-xs rounded-m">
                        <i className="bi bi-bell-fill font-17"></i>
                        <em className="badge bg-red-dark color-white scale-box">3</em>
                    </a>
                </div> */}
      </div>
    </div>
  );
}

export default Header;
