import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../service/auth.service";
import userService from "../service/user.service";

function MenuSidebar() {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);

  const getWalletInfo = async () => {
    try {
      const getWallet = await userService.getBalance();
      setCurrentUser(getWallet.data.user)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      getWalletInfo();
    } else {
      setCurrentUser(null);
    }
  }, [localStorage.getItem("user")]);


  return (
    <div
      id="menu-sidebar"
      data-menu-active="nav-welcome"
      className="offcanvas offcanvas-start offcanvas-detached d-block"
    >
      <div className="menu-size" style={{ width: "230px" }}>
        <div>
          <div className="page-title custom_title sidebar-title d-flex ">
            {currentUser ? (
              <div className="w-100 text-center">
                <h1>Welcome</h1>
                <h2>{currentUser.username}</h2>
              </div>
            ) : (
              <div className="w-100 text-center">
                <h1>Welcome</h1>
                <p>Please Login in Here</p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/signin");
                  }}
                >
                  Login
                </button>
              </div>
            )}
          </div>
          <div className="list-group list-custom list-menu-large">
            <a
              href="/"
              id="nav-welcome"
              className="list-group-item list-group-item-active"
              onClick={(e) => {
                e.preventDefault();
                navigate("/deposit");
              }}
            >
              <div style={{ paddingLeft: "0px" }}>Deposit</div>
              <img
                src={require("../images/deposit.png")}
                alt=""
                width="17px"
                height="17px"
              />
            </a>
            <a
              href="/"
              id="nav-pages"
              className="list-group-item"
              onClick={(e) => {
                e.preventDefault();
                navigate("/withdraw");
              }}
            >
              <div style={{ paddingLeft: "0px" }}>Withdraw</div>
              <img
                src={require("../images/withdraw.png")}
                alt=""
                width="17px"
                height="17px"
              />
            </a>
            <a
              href="/"
              id="nav-pages"
              className="list-group-item"
              onClick={(e) => {
                e.preventDefault();
                navigate("/gameaccount");
              }}
            >
              <div style={{ paddingLeft: "0px" }}>Game Account</div>
              <img
                src={require("../images/withdraw.png")}
                alt=""
                width="17px"
                height="17px"
              />
            </a>
            <a
              href="/"
              id="nav-homes"
              className="list-group-item"
              onClick={(e) => {
                e.preventDefault();
                navigate("/promotions");
              }}
            >
              <div style={{ paddingLeft: "0px" }}>Promotions</div>
              <img
                src={require("../images/promo.png")}
                alt=""
                width="17px"
                height="17px"
              />
            </a>
            <a
              href="/"
              id="nav-comps"
              className="list-group-item"
              onClick={(e) => {
                e.preventDefault();
                navigate("/history");
              }}
            >
              <div style={{ paddingLeft: "0px" }}>Transaction History</div>
              <img
                src={require("../images/transfer.png")}
                alt=""
                width="17px"
                height="17px"
              />
            </a>
            <a
              data-bs-toggle="offcanvas"
              data-bs-target="#menu-highlights"
              href="/"
              className="list-group-item"
              onClick={(e) => {
                e.preventDefault();
                navigate("/account");
              }}
            >
              <div style={{ paddingLeft: "0px" }}>Account</div>
              <img
                src={require("../images/setting.png")}
                alt=""
                width="17px"
                height="17px"
              />
            </a>
          </div>
          <div className="list-group list-custom list-menu-small pt-4">
            <a
              href="/"
              className="list-group-item"
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            >
              <div className="pt-4 d-flex align-items-center flex-column justify-content-center w-100">
                <img src={require("../images/nav-chat.png")} alt="" />
                <p className="font-12">Customer Service</p>
              </div>
            </a>
          </div>

          <a href="/" className="btn_gold">
            {" "}
            24/7
          </a>
        </div>
      </div>
    </div>
  );
}

export default MenuSidebar;
