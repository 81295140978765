import React from 'react'
import formatDate from '../utils/formatDate';

function Transactions({ orders, handleDelete, type }) {
    return (

        <div id="menu-notifications"
            className="rounded-m d-block mx-2"
        // style={{ backgroundColor: "#fff" }}
        >
            <div className="menu-size" style={{ height: "75vh" }}>
                <div className="content pb-2">
                    {
                        orders && orders.map((order, index) => {
                            console.log(order)
                            const dateTime = formatDate(order.created_at)
                            return (
                                <div key={index}>
                                    <a className="d-flex py-1 justify-content-between">
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{order.ttype.toLowerCase() == "wd" ? "Withdrawal" : (order.ttype.toLowerCase() === "bt" ? "Bonus" : "Deposit")} ${order.amount} {String(order.state).toUpperCase()}</h5>
                                            <p className="mb-0 font-11 opacity-70">14th March 03:14 AM</p>
                                        </div>
                                        <div className="align-self-center">
                                            <span className="">
                                                {
                                                    order.state === 'approved' && <i className="fa fa-check  fa-2x" style={{ color: 'green' }}></i>
                                                }
                                                {
                                                    order.state === 'error' && <i className="fa-solid fa-ban color-red-dark fa-2x"></i>
                                                }
                                                {
                                                    order.state === 'rejected' && <i className="fa-solid fa-circle-minus color-red-dark fa-2x"></i>
                                                }
                                                {
                                                    order.state === 'deleted' &&
                                                    <i className="fa fa-trash fa-2x" style={{ color: 'black' }}></i>

                                                }
                                                {
                                                    order.state === 'pending' &&
                                                    <div className='d-flex'>
                                                        <i className="fa fa-spinner fa-2x" style={{ color: "#e2a820" }}></i>
                                                        <i
                                                            className="fa-solid fa-circle-xmark color-red-dark fa-2x  ml-1"
                                                            onClick={() => handleDelete(order.id, type)}
                                                        ></i>
                                                    </div>
                                                }
                                            </span>
                                        </div>
                                    </a>
                                    <div className="divider my-2"></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Transactions