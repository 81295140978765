import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../service/auth.service';

const BalanceWrapper = ({ newsfeeds, startedGamelist, StopProduct, setStartedGamelist }) => {
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, [localStorage.getItem("user")]);

  return (
    <>
      <div className="announcement">
        <div>
          <img src={require("../images/announcement.png")} alt="" />
          Announcement :
        </div>
        <div className="running_text">
          <marquee scrolldelay="200" data-dom="announcement" className="dom-registered">
            {newsfeeds.map((feed, newsindex) => (
              <span key={newsindex}>
                {feed.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            ))}
          </marquee>
        </div>
      </div>


      <div className="balance_wrapper">
        {!currentUser ?
          <div className="links">
            <a onClick={e => {
              e.preventDefault()
              navigate("/signin")
            }}>
              <img src={require("../images/btn-login.png")} alt="" className='img-fluid' />
              <span>Login</span></a>
            <a className='register' onClick={e => {
              e.preventDefault()
              navigate("/signup")
            }}>
              <img src={require("../images/btn-register.png")} alt="" className='img-fluid' />
              <span>Register</span></a>
          </div>
          :
          <div className="links">
            <a
              onClick={() => {
                authService.logout();
                setCurrentUser(null);
                navigate("/");
              }}
            >
              <img src={require("../images/btn-login.png")} alt="" className='img-fluid' />
              <span>Log Out</span>
            </a>
          </div>
        }
        <div className="pbalance">
          <div className="balance_btns">
            {currentUser ?
              <a
                className="btns"
                onClick={e => {
                  e.preventDefault()
                  navigate("/deposit")
                }}
              >
                <span className="main_rounded">
                  <img src={require("../../src/images/deposit.png")} alt="" width="25px" height="25px" />
                </span>
                Deposit
              </a>
              :
              <a className="btns" onClick={e => {
                e.preventDefault()
                navigate("/signin")
              }}>
                <span className="main_rounded">
                  <img src={require("../../src/images/deposit.png")} alt="" width="25px" height="25px" />
                </span>
                Deposit
              </a>
            }

            {currentUser ?
              <a
                className="btns withdraw_btn"
                onClick={async (e) => {
                  startedGamelist.forEach(startedGame => {
                    StopProduct(startedGame.id)
                  })
                  setStartedGamelist([])
                }}
              >
                <span className="main_rounded">
                  <img src={require("../../src/images/transfer.png")} alt="" width="25px" height="25px" />
                </span>
                Refresh
              </a>
              :
              <a
                className="btns withdraw_btn"
                onClick={e => {
                  e.preventDefault()
                  navigate("/signin")
                }}>
                <span className="main_rounded">
                  <img src={require("../../src/images/transfer.png")} alt="" width="25px" height="25px" />
                </span>
                Refresh
              </a>
            }

            {
              currentUser ?
                <a
                  className="btns refresh_btn"
                  onClick={e => {
                    e.preventDefault()
                    navigate("/withdraw")
                  }} >
                  <span className="main_rounded">
                    <img src={require("../../src/images/withdraw.png")} alt="" width="25px" height="25px" />
                  </span>
                  Withdraw
                </a>
                :
                <a
                  className="btns refresh_btn"
                  onClick={e => {
                    e.preventDefault()
                    navigate("/signin")
                  }} >
                  <span className="main_rounded">
                    <img src={require("../../src/images/withdraw.png")} alt="" width="25px" height="25px" />
                  </span>
                  Withdraw
                </a>
            }

            {
              currentUser ?
                <a
                  className="btns refresh_btn"
                  onClick={e => {
                    e.preventDefault()
                    navigate("/home")
                  }} >
                  <span className="main_rounded">
                    <img src={require("../../src/images/notification.png")} alt="" width="25px" height="25px" />
                  </span>
                  Notification
                </a>
                :
                <a
                  className="btns refresh_btn"
                  onClick={e => {
                    e.preventDefault()
                    navigate("/signin")
                  }} >
                  <span className="main_rounded">
                    <img src={require("../../src/images/notification.png")} alt="" width="25px" height="25px" />
                  </span>
                  Notification
                </a>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default BalanceWrapper