import React, { useEffect, useState } from 'react'
import { Radio, Space, Modal } from 'antd';
import userService from '../service/user.service';
import Loading from './Loading';

const amountlist = [
    { id: 1, value: "10" },
    { id: 2, value: "30" },
    { id: 3, value: "50" },
    { id: 4, value: "100" },
    { id: 5, value: "200" },
    { id: 6, value: "250" },
    { id: 7, value: "300" },
    { id: 8, value: "500" },
];

const initialValues = {
    ttype: "PG",
    amount: '',
};

function DepositTabs() {
    const [values, setValues] = useState(initialValues);
    const [radioValue, setRadioValue] = useState(1);
    const [validations, setValidations] = useState({
        amount: "",
        non_field_errors: ""
    })
    const [promotionList, setPromotionList] = useState([]);
    const [selectedTnC, setSelectedTnC] = useState({
        title: '',
        description: ''
    });
    const [isLoading, setLoading] = useState(false);

    const {
        amount: amountVal,
        non_field_errors: nonVal,
    } = validations

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }


    const onChangeRadio = (e) => {
        setRadioValue(e.target.value);
    };

    const handleDeposit = async e => {
        e.preventDefault();

        console.log("values ==> ", values);

        setLoading(true);
        let response;
        try {
            response = await userService.createDeposit(values);


            const url = response.data.action.url;
            const bankdata = response.data.action.data;

            let form_data = new FormData();

            Object.entries(bankdata).forEach(([key, value]) => {
                form_data.append(`${key}`, value);
            })

            try {

                var form = document.createElement("form");

                form.method = "POST";
                form.action = url;

                let element = [{}];
                Object.entries(bankdata).forEach(([key, value], index) => {
                    element[index] = document.createElement("input");
                    element[index].name = key;
                    element[index].value = value;
                    form.appendChild(element[index]);
                })

                document.body.appendChild(form);
                form.style = "display: none";

                form.submit();

                document.body.removeChild(form);

            } catch (error) {
                console.log("error ==> ", error);
            }

        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let non_msg;

            if (err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }

            setValidations({
                ...validations, amount: amount_msg, non_field_errors: non_msg
            });
        }

        setLoading(false);
    }

    const getPromotions = async () => {
        setLoading(true);
        const promotionListing = await userService.getPromotions();
        const nonObj = {
            id: 0,
            title: "No promotion",
            active: true
        }

        promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);

        setPromotionList(promotionListing.data);
        setSelectedTnC(promotionListing.data[0]);
        setRadioValue(promotionListing.data[0].id);
        setLoading(false);
    }

    useEffect(() => {
        getPromotions();
    }, [])

    return (
        <div className="content m-0">
            <div className="tabs tabs-pill" id="tab-group-2">
                <div className="mt-3"></div>
                <div className="collapse show" id="tab-5" data-bs-parent="#tab-group-2">
                    <div className="form-custom form-label form-icon mb-3 bg-transparent">
                        <input
                            name="amount"
                            type="text"
                            className="form-control rounded-xxs mb-3"
                            id="c1a"
                            placeholder="Amount"
                            onChange={handleNumChange}
                            value={values.amount}
                        />
                        <label htmlFor="c1a" className="color-theme">Amount</label>
                        <span>(required)</span>
                        <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {amountlist.map((item, i) => (
                            <button
                                key={i}
                                type="button"
                                className="btn-full btn border-blue-dark color-blue-dark text-center mb-2"
                                onClick={() => setValues({ ...values, amount: item.value })}
                            >
                                {item.value}
                            </button>
                        ))}
                    </div>
                    <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div>
                    <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
                        <Space direction="vertical">
                            {(promotionList && promotionList.length > 0) && promotionList.filter(item => item.active).map(item => {
                                return (
                                    <Radio value={item.id} key={item.id} onChange={() => setSelectedTnC(item)} onClick={() => {
                                        // setModalOpen(true);
                                        if (item.id !== 0) {
                                            setValues(values => {
                                                return { ...values, promotions_applied: item.id }
                                            })
                                        } else {
                                            delete values.promotions_applied;
                                        }
                                    }}>{item.title}</Radio>
                                )
                            })}
                        </Space>
                    </Radio.Group>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <button className="btn btn-xl gradient-highlight shadow-bg shadow-bg-s mt-4 ms-auto"
                    onClick={handleDeposit}
                >
                    Deposit
                </button>
            </div>
            <div className="d-flex align-items-center flex-column my-3">
                <i className="fa-brands fa-whatsapp color-dark-green fa-3x" style={{ color: "#25D366" }}></i>
                <h3 style={{ color: "#223651" }}>Live chat support</h3>
            </div>
            {
                isLoading && <Loading />
            }
        </div>
    )
}

export default DepositTabs